import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
     <path d="M4839 6408 c0 -315 -2 -366 -13 -327 -27 102 -96 182 -194 226 -46
22 -64 24 -140 21 -92 -4 -137 -19 -184 -63 -15 -14 -30 -25 -33 -25 -3 0 -5
16 -5 35 l0 35 -115 0 -115 0 -1 -132 0 -133 -19 61 c-33 103 -121 184 -232
214 -26 7 -84 11 -135 9 -71 -3 -99 -9 -143 -31 -90 -44 -173 -139 -201 -228
-4 -14 -8 129 -8 318 l-1 342 -125 0 -125 0 -2 -217 -3 -218 -177 -3 -178 -2
0 220 0 220 -125 0 -125 0 0 -550 0 -550 125 0 125 0 0 220 0 220 180 0 180 0
0 -220 0 -220 125 0 125 0 1 138 c0 133 1 136 14 92 34 -107 148 -207 269
-237 139 -34 320 16 401 109 19 23 35 45 35 50 0 4 -36 26 -80 48 l-81 41 -40
-33 c-33 -27 -50 -33 -103 -36 -79 -5 -135 20 -160 72 -33 69 -44 66 234 66
l250 0 0 -255 0 -255 -165 0 -165 0 0 -550 0 -550 177 0 c97 0 206 5 242 11
102 17 221 79 294 152 108 108 157 230 157 392 0 103 -18 185 -58 259 -33 63
-153 186 -209 214 l-41 22 -1 155 c-1 85 1 155 3 155 3 0 26 -13 53 -30 86
-53 213 -62 309 -20 83 36 159 126 189 223 13 39 14 33 14 -95 l1 -138 120 0
120 0 0 213 c0 191 2 215 19 241 27 40 70 66 113 66 50 0 79 -15 106 -54 21
-31 22 -41 22 -249 l0 -217 575 0 575 0 0 339 0 340 -117 3 c-65 2 -265 2
-445 0 l-328 -3 0 -84 0 -85 135 0 c84 0 135 -4 135 -10 0 -6 -65 -108 -145
-228 l-145 -217 0 211 c0 241 -9 289 -64 346 -51 53 -118 80 -208 86 -86 5
-119 -4 -183 -50 l-40 -29 -5 263 -5 263 -117 3 -118 3 -1 -373z m1411 -368
l0 -230 -156 0 c-86 0 -154 4 -152 8 5 13 301 452 305 452 2 0 3 -103 3 -230z
m-2499 124 c25 -12 59 -64 59 -89 0 -3 -58 -5 -130 -5 -125 0 -130 1 -130 21
0 12 15 36 34 55 30 30 40 34 85 34 29 0 66 -7 82 -16z m763 -52 c48 -28 69
-66 74 -129 3 -48 0 -64 -20 -92 -39 -58 -72 -76 -138 -76 -63 0 -95 16 -133
67 -24 32 -33 105 -18 150 30 91 147 131 235 80z m-375 -931 c66 -34 109 -79
142 -148 29 -61 31 -70 27 -161 -4 -111 -22 -159 -90 -229 -49 -50 -112 -78
-195 -88 l-63 -7 0 332 0 333 69 -6 c40 -3 87 -15 110 -26z"/>
<path d="M6547 6774 c-3 -3 -6 -262 -7 -575 l0 -569 115 0 115 0 0 35 c0 19 2
35 5 35 3 0 28 -15 55 -34 91 -62 212 -70 318 -21 58 27 132 98 152 144 16 39
24 39 40 0 19 -46 98 -121 155 -148 39 -19 68 -24 130 -25 91 -1 138 12 191
54 37 30 56 31 53 2 -4 -41 -2 -42 116 -42 l116 0 -1 340 -2 340 -119 0 -119
0 0 -31 0 -31 -30 25 c-74 63 -246 77 -343 27 -54 -27 -126 -99 -147 -147 -8
-18 -17 -33 -20 -33 -3 0 -12 15 -20 33 -21 48 -93 120 -147 147 -97 50 -269
36 -343 -27 l-30 -25 0 266 0 266 -113 0 c-63 0 -116 -3 -120 -6z m458 -654
c11 -5 33 -22 50 -39 24 -26 30 -41 33 -95 7 -101 -38 -160 -131 -172 -82 -11
-151 30 -176 105 -16 49 -14 79 9 125 41 82 123 111 215 76z m793 -13 c37 -25
72 -89 72 -133 0 -40 -39 -113 -73 -138 -38 -28 -141 -29 -181 -3 -46 30 -66
75 -66 144 0 58 3 66 35 101 19 22 49 44 67 50 44 14 106 5 146 -21z"/>
<path d="M8149 6768 c0 -7 -2 -266 -2 -575 l-2 -563 117 0 117 0 3 220 3 220
37 36 c30 30 45 38 87 42 63 6 95 -13 122 -71 16 -37 19 -67 19 -244 l0 -203
116 0 115 0 -3 263 c-3 249 -4 264 -25 304 -44 82 -145 133 -264 133 -76 0
-131 -20 -181 -68 l-28 -26 0 272 0 272 -115 0 c-85 0 -115 -3 -116 -12z"/>
<path d="M6312 6724 c-78 -40 -101 -139 -49 -207 62 -81 185 -69 232 23 58
112 -71 241 -183 184z"/>
<path d="M4907 5418 c-3 -7 -51 -125 -107 -263 -77 -191 -289 -717 -327 -812
-4 -10 26 -13 128 -13 l134 0 40 110 40 110 214 -2 214 -3 14 -40 c7 -22 25
-70 39 -107 l26 -68 133 0 134 0 -145 358 c-80 196 -180 444 -222 549 l-77
193 -116 0 c-84 0 -118 -4 -122 -12z m187 -490 c31 -81 56 -150 56 -153 0 -3
-54 -5 -120 -5 -88 0 -120 3 -120 12 0 26 114 308 121 301 4 -4 33 -74 63
-155z"/>
<path d="M5450 5325 l0 -105 130 0 130 0 0 -445 0 -445 125 0 125 0 2 443 3
442 133 3 132 3 0 104 0 105 -390 0 -390 0 0 -105z"/>
<path d="M6333 4933 c-111 -274 -211 -522 -222 -550 l-21 -53 133 0 132 0 38
99 c20 54 37 103 37 110 0 8 56 11 217 9 l218 -3 39 -107 39 -108 133 0 134 0
-21 53 c-17 42 -180 447 -391 970 l-32 77 -116 0 -116 0 -201 -497z m380 4
c31 -84 57 -156 57 -160 0 -9 -240 -9 -240 1 -1 13 115 312 120 312 3 0 31
-69 63 -153z"/>
<path d="M3581 4086 c-17 -21 -10 -50 14 -54 27 -5 46 -22 39 -34 -4 -6 -19
-7 -35 -4 -35 7 -39 -11 -5 -20 31 -7 68 12 64 34 -2 9 -18 24 -35 32 -38 18
-37 36 1 33 17 -1 26 2 23 10 -6 18 -52 20 -66 3z"/>
<path d="M3750 4047 c0 -51 3 -59 24 -68 17 -8 29 -8 45 0 18 10 21 21 21 68
0 67 -17 61 -22 -7 -2 -37 -6 -45 -23 -45 -17 0 -21 8 -23 45 -5 69 -22 74
-22 7z"/>
<path d="M3934 4087 c-3 -8 -4 -36 -2 -63 l3 -49 39 0 c43 0 64 21 51 51 -4
11 -8 30 -9 44 -1 21 -6 25 -39 28 -25 2 -39 -1 -43 -11z m64 -24 c2 -8 -5
-13 -17 -13 -12 0 -21 6 -21 16 0 18 31 15 38 -3z m7 -53 c0 -8 -10 -16 -22
-18 -18 -3 -23 2 -23 18 0 16 5 21 23 18 12 -2 22 -10 22 -18z"/>
<path d="M4125 4086 c-25 -18 -13 -44 25 -58 39 -13 33 -34 -9 -30 -38 3 -41
-15 -4 -24 32 -8 57 6 61 34 3 16 -5 25 -33 36 -43 18 -38 40 6 31 30 -7 41 8
13 19 -24 9 -38 7 -59 -8z"/>
<path d="M4304 4075 c-20 -20 -24 -32 -19 -53 3 -15 13 -33 22 -39 20 -15 59
-17 68 -3 3 6 -3 10 -14 10 -36 0 -51 12 -51 40 0 34 22 54 51 45 15 -5 20 -3
17 6 -8 25 -46 22 -74 -6z"/>
<path d="M4470 4034 c0 -41 4 -63 10 -59 6 3 10 15 10 26 0 29 38 25 50 -6 12
-32 34 -34 24 -2 -5 12 -7 35 -6 51 2 40 -14 56 -54 56 l-34 0 0 -66z m67 30
c7 -18 -14 -35 -34 -27 -7 3 -13 14 -13 24 0 24 38 26 47 3z"/>
<path d="M4657 4093 c-11 -10 -8 -111 3 -118 6 -4 10 18 10 59 0 66 -1 72 -13
59z"/>
<path d="M4770 4034 c0 -41 4 -63 10 -59 6 3 10 15 10 25 0 13 8 20 28 22 54
6 37 78 -19 78 l-29 0 0 -66z m58 23 c2 -12 -3 -17 -17 -17 -15 0 -21 6 -21
21 0 25 33 22 38 -4z"/>
<path d="M4935 4090 c-3 -5 3 -10 14 -10 19 0 21 -6 21 -55 0 -30 5 -55 10
-55 6 0 10 23 10 51 0 42 3 52 20 56 11 3 20 9 20 14 0 12 -87 11 -95 -1z"/>
<path d="M5115 4088 c-9 -24 -1 -113 10 -111 14 4 19 103 6 116 -7 7 -12 5
-16 -5z"/>
<path d="M5240 4073 c-34 -44 -8 -103 45 -103 52 0 76 84 33 114 -32 23 -54
20 -78 -11z m68 -5 c30 -30 -2 -93 -36 -71 -21 13 -27 50 -12 69 15 17 32 18
48 2z"/>
<path d="M5430 4035 c0 -37 4 -65 10 -65 6 0 10 19 10 43 l0 42 40 -45 39 -45
-1 70 c-1 46 -3 56 -5 28 -2 -24 -8 -43 -12 -43 -4 0 -20 18 -37 40 -16 22
-32 40 -36 40 -5 0 -8 -29 -8 -65z"/>
<path d="M5747 4094 c-11 -11 -8 -124 3 -124 6 0 10 18 10 41 l0 41 20 -23 20
-24 20 25 20 25 0 -42 c0 -24 5 -43 10 -43 12 0 14 117 2 124 -5 3 -16 -8 -26
-25 -22 -37 -29 -36 -53 1 -11 17 -22 27 -26 24z"/>
<path d="M5966 4045 c-29 -63 -31 -75 -16 -75 6 0 10 7 10 15 0 22 56 20 69
-2 5 -10 13 -15 17 -11 7 7 -30 105 -47 122 -4 5 -19 -16 -33 -49z m44 -17 c0
-5 -7 -8 -15 -8 -17 0 -18 2 -9 25 5 13 8 14 15 3 5 -7 9 -16 9 -20z"/>
<path d="M6137 4093 c-4 -3 -7 -33 -7 -65 l0 -58 33 0 c49 0 71 21 71 65 0 25
-6 42 -19 51 -20 15 -67 19 -78 7z m61 -22 c8 -5 12 -22 10 -42 -2 -29 -7 -34
-30 -37 -27 -3 -28 -2 -28 42 0 47 15 59 48 37z"/>
<path d="M6324 4086 c-3 -7 -4 -35 -2 -62 3 -49 3 -49 41 -52 21 -2 37 1 37 7
0 6 -11 11 -25 11 -18 0 -25 5 -25 20 0 15 7 20 26 20 14 0 23 4 19 10 -3 6
-15 10 -26 10 -10 0 -19 7 -19 15 0 10 10 15 31 15 17 0 28 4 24 10 -9 15 -75
12 -81 -4z"/>
<path d="M6604 4086 c-3 -7 -4 -35 -2 -62 3 -49 3 -49 41 -52 21 -2 37 1 37 7
0 6 -11 11 -25 11 -18 0 -25 5 -25 20 0 15 7 20 26 20 14 0 23 4 19 10 -3 6
-15 10 -26 10 -10 0 -19 7 -19 15 0 9 9 15 25 15 14 0 25 5 25 10 0 16 -70 12
-76 -4z"/>
<path d="M6792 4059 c-28 -65 -35 -89 -24 -89 6 0 12 7 16 15 8 21 53 19 65
-2 21 -39 22 0 1 49 -31 75 -37 77 -58 27z m38 -31 c0 -5 -7 -8 -15 -8 -17 0
-18 2 -9 25 5 13 8 14 15 3 5 -7 9 -16 9 -20z"/>
<path d="M6962 4088 c-23 -23 -13 -47 24 -63 42 -17 37 -40 -7 -31 -33 6 -40
-9 -9 -19 26 -8 57 2 64 22 8 20 -8 39 -39 47 -14 4 -25 14 -25 22 0 13 7 15
30 11 31 -7 42 6 14 17 -24 9 -39 7 -52 -6z"/>
<path d="M7118 4094 c-4 -4 1 -19 12 -34 11 -16 20 -40 20 -53 0 -14 5 -29 10
-32 6 -4 10 6 10 22 0 15 9 39 20 53 29 37 25 61 -5 28 l-24 -28 -18 26 c-10
14 -22 22 -25 18z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
